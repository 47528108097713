<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <Header />
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <CoverImage />
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class=" align-items-center auth-bg px-2 p-lg-5"
      >
        <div class="row">
          <div class=" mx-auto col-12 margin-top-icon-sm">
            <MediaIcon />
          </div>
          <div class="px-xl-2 mx-auto col-12 text-margin-top-sm">
            <b-card-title class="mb-1">
              Forgot Something? 🔒
            </b-card-title>

            <b-form class="auth-forgot-password-form mt-2">
              <!-- username -->
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio
                  v-model="forget_username_password"
                  name="some-radios"
                  value="username"
                  class="mb-1"
                >
                  Forgot username
                </b-form-radio>
                <b-form-radio
                  v-model="forget_username_password"
                  name="some-radios"
                  value="password"
                >
                  Forgot password
                </b-form-radio>
              </b-form-group>

              <b-button
                type="button"
                variant="primary"
                block
                :disabled="isDisabled"
                @click="chooseUsernameOrPassword"
              >
                Continue
              </b-button>
            </b-form>
            <!-- form -->
          </div>
        </div>
      </b-col>
      <!-- /Forgot password-->

      <!-- /footer-->
      <b-col
        lg="12"
        class="mt-auto"
      >
        <Footer />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BLink,
  BRow, BFormRadio, VBTooltip, BInputGroupAppend, BInputGroup, BAlert,
} from 'bootstrap-vue'
import Header from '@core/components/landding-page/Header.vue'
import CoverImage from '@core/components/landding-page/CoverImage.vue'
import MediaIcon from '@core/components/landding-page/MediaIcon.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Footer from '@core/components/landding-page/Footer.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    BAlert,
    BFormRadio,
    // VuexyLogo,

    Header,
    MediaIcon,
    Footer,
    CoverImage,
  },

  data() {
    return {
      forget_username_password: '',
      isDisabled: true,
    }
  },
  computed: {

  },
  watch: {
    forget_username_password(val) {
      this.isDisabled = val === ''
    },
  },

  methods: {
    chooseUsernameOrPassword() {
      if (this.forget_username_password === 'username') {
        router.push({ name: 'auth-forgot-username' })
      }

      if (this.forget_username_password === 'password') {
        router.push({ name: 'auth-forgot-password' })
      }
    },
  },
}

</script>

<style scoped lang="scss">

</style>
